@import 'styles/vars.scss';

.admin-panel {
    position: relative;
    width: 8rem;
    height: 100%;
    user-select: none;

    .public-logo {
        margin: 2.5rem 2rem 3rem;
        color: #fff;
        overflow: hidden;
    }

    .user-name {
        position: relative;
        overflow: hidden;
        max-width: 60%;

        &:after {
            content: '';
            width: 4rem;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background: linear-gradient(90deg, rgba(26,36,51,0) 0%, var(--color-active-dark) 100%);
            transition: opacity .1s ease;
            pointer-events: none;
        }
    }

    .user-image .image-container {
        border-radius: 1.25rem;
        overflow: hidden;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        width: inherit;
        height: 100%;
        background: var(--color-active-dark);
        overflow: hidden;
        transition: width 0.125s;

        &.expanded {
            width: 32rem;

            .navigation-item:after {
                opacity: 1;
            }

            .copyright {
                opacity: 1;
            }

            .lang-switch {
                letter-spacing: 0;
                color: var(--color-passive-mark);
                transition: letter-spacing .1s ease, color .2s ease;
            }
        }
    }

    .admin-panel-foot {
        display: flex;
        align-items: center;
        padding: 3rem 2rem;
    }

    .lang-switch {
        color: var(--color-passive-mark);
        white-space: nowrap;
        text-align: center;
        color: transparent;
        transition: color .2s ease;
        margin-right: 0;
        flex-direction: row-reverse;
        justify-content: flex-end;

        svg {
            color: var(--color-passive-mark);
            margin-left: .5rem;
            margin-right: 1rem;
            flex: 0 0 auto;
        }
    }

    .legal-notes {
        margin: 2rem;
    }

    .copyright {
        opacity: 0;
        transition: opacity 0.25s;
        font-size: 1.625rem;
        color: var(--color-passive-dark);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-weight: 600;
        margin-top: 2rem;
        white-space: nowrap;

        img {
            height: 2rem;
            width: auto;
            margin-left: 1rem;
        }
    }
}

.panel-switch {
    margin-left: 2rem;
}

@media screen and (max-width: $mobile-breakpoint) {
    .admin-panel {
        position: absolute;
        left: -8rem;

        .content {
            left: 0;
            transition: width .2s ease, left .2s ease;

            .user-panel-control .user-image {
                opacity: 0;
            }

            &.expanded {
                left: 8rem;
                width: 100vw;
            }
        }
    }
}