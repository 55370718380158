.tag {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .tag-icon {
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.75rem;
        border-radius: 100%;
        vertical-align: middle;
    }
    
    .tag-name {
        vertical-align: middle;
    }

    /*&.deleted {
        opacity: .5;

        &:after {
            content: ' (Deleted)';
        }
    }*/
}