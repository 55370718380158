.account-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
    color: var(--color-passive-light);

    .account-header {
        text-align: center;
    }
    
    .logo {
        padding: 4rem 0;
    }
    
    .account-content {
        width: 32rem;
        padding: 4rem 0;
    }
}