.wallets-content {

    .wallet-empty-placeholder {
        font-size: 1.625rem !important;
        line-height: 3rem;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        text-align: center;
        padding: 0 2rem;
        margin-left: 0;
    }

    .ant-table-tbody {
        td {
            height: 15rem;
        }
    }

    .ant-table-thead {
        colgroup col:last-child {
            width: auto !important;
        }
        
        th {
            transition: none;

            &:last-child {
                vertical-align: middle;
                text-align: right;
                line-height: 0;
            }
        }
    }

    .separate-logo {
        width: 8rem;
        height: 8rem;
        border-radius: .5rem;
        position: relative;

        .image-container {
            border: 1px solid var(--color-passive-medium);
            width: 100%;
            height: 100%;
            border-radius: .5rem;
            overflow: hidden;
        }

        img {
            object-fit: cover;
        }
    }

    .separate-photo {
        width: 6rem;
        height: 8rem;
        border-radius: .5rem;
        position: relative;

        .image-container {
            width: 100%;
            height: 100%;
            border: 1px solid var(--color-passive-medium);
            border-radius: .5rem;
            overflow: hidden;
        }

        img {
            object-fit: cover;
        }
    }

    .logo-and-photo {
        display: flex;
        padding: 0;
        margin-right: 1rem;
        border-radius: .5rem;
        background: url(//tabee.app/files/placeholder.jpg) no-repeat center center;
        background-size: cover;
        width: 14rem;
        height: 8rem;
        position: relative;

        .photo {
            width: 6rem;
            flex: 0 0 6rem;
            border: 1px solid var(--color-passive-medium);
            border-right: none;
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
            overflow: hidden;

            img {
                border-top-left-radius: .5rem;
                border-bottom-left-radius: .5rem;
            }
        }

        .logo {
            border: 1px solid var(--color-passive-medium);
            border-left: none;
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
            overflow: hidden;

            img {
                border-top-right-radius: .5rem;
                border-bottom-right-radius: .5rem;
            }
        }
    }

    .row-icons {
        position: absolute;
        right: -1rem;
        top: .25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        svg {
            margin-top: .5rem;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}