.todo-table .ant-table {
    border-left: 1px solid var(--color-border);
    
    tr:nth-child(odd) td {
        background-color: #fff;
    }

    td:first-child {
        border-left: 0;
    }

    tr:last-child td {
        border-bottom: 0;
    }
}

.todo-sub-row,
.todo-next-row {
    display: none;

    &.visible {
        display: table-row;
    }
}

.todo-next-row,
.todo-sub-row {
    td.leading-cell:before {
        content: '';
        position: absolute;
        left: 0;
        top: -30%;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 0;
        background-position: center;
        background-size: 2px 1rem;
        background-repeat: repeat-y;
        background-image: linear-gradient(to bottom, #999 0%, transparent 40%);
    }

    &.previous-done td.leading-cell:before {
        background-image: linear-gradient(to bottom, #23b7f9 0%, transparent 40%);
    }
}

.ant-table {
    td {
        .todo-priority {
            color: var(--color-active-brand);
            padding-right: .5em;
        }
        
        .outdated {
            color: var(--color-active-alert);
        }

        .todo-set-due {
            color: var(--color-active-brand);
        }

        .todo-sub-add {
            color: var(--color-active-brand);
            cursor: pointer;
        }

        .sub-toggle {
            width: 3rem;
            height: 3rem;
            border-radius: 2rem;
            border: 1px solid var(--color-active-brand);
            transition: transform .2s ease;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:before {
                content: '';  
                width: 1rem;
                height: 1rem;
                border: 1px solid var(--color-active-brand);
                transform: rotate(-45deg) translate(-25%, -15%);
                border-top-color: transparent;
                border-left-color: transparent;
            }

            &.open {
                transform: rotate(90deg);
            }

            &.disabled {
                border-color: var(--color-passive-mark);

                &:before {
                    border-right-color: var(--color-passive-mark);
                    border-bottom-color: var(--color-passive-mark);
                }
            }
        }
    }

    .todo-sub-row {
        td {
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }
}