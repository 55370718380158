.ant-color-picker {
    .ant-input-group-addon {
        padding: 0 4px;
    }

    .ant-input[type='color'] {
        padding: 0;
        border: none;
        width: 24px;
        height: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: none;
        background-color: transparent;

        &::-webkit-color-swatch-wrapper {
            height: 12px;
            width: 12px;
            padding: 0;
            border: none;
            box-shadow: none;
        }

        &::-webkit-color-swatch {
            border-radius: 24px;
        }
    }
}