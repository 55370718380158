
.label-in {
    position: relative;
    color: inherit;

    .label-in-label {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        padding: 8px 0;
        color: inherit;
        opacity: .5;
        font: 600 16px / 4rem 'Open Sans', Arial, sans-serif;
        transition: all .2s ease;
    }

    input[value]:not([value=""]) + .label-in-label {
        font-size: 12px;
        line-height: 12px;
        padding: 0;
    }
}