.ant-table-tbody > tr > td {
    vertical-align: middle;
    border-left: 1px solid var(--color-passive-medium);
}

.ant-table-thead > tr > th {

    user-select: none;

    &,
    &.ant-table-column-sort,
    &.ant-table-column-has-sorters:hover {
        background-color: #f3f4f5;
        color: #1A2433;
        font-weight: 600;
        padding-top: 0;
        padding-bottom: 0;
        border-left: 1px solid #dedfe1;
        border-top: 1px solid var(--color-border);
        border-bottom: 1px solid #e8e9ea;
    }
}

.ant-table-tbody > tr.ant-table-row.current-row > td,
.ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #e9f8ff;
}

.ant-table {

    font-size: 1.65rem;

    tr:nth-child(odd) td {
        background-color: #f7f7f8;
    }

    tr[data-row-key="reset"] td {
        background-color: transparent;
    }

    td.ant-table-cell {
        vertical-align: middle;
    }

    .ant-table-column-sorter-inner {
        display: none;
    }

    td.ant-table-column-sort {
        background-color: #fff;
    }

    .ant-table-column-sort {
        .ant-table-column-sorter-inner {
            display: inline-block;

            svg {
                display: none;
            }

            .ant-table-column-sorter-up,
            .ant-table-column-sorter-down {
                width: 1.5rem;
                height: 2.25rem;
                background: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+DQogIDxwYXRoIGZpbGw9IiMxQTI0MzMiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTUuOTk5OTkgMTMuMTk2NUwxMC41MTIxIDlIMS40ODc4NWw0LjUxMjE0IDQuMTk2NXoiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPg0KICA8cGF0aCBzdHJva2U9IiMxQTI0MzMiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTYgMTJWNC41Ii8+DQo8L3N2Zz4=) no-repeat center center;
                background-size: contain;
                display: none;
                transform: translateY(.2rem);

                &.active {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            .ant-table-column-sorter-down {
                transform: rotate(180deg) translateY(-.2rem);
            }
        }
    }
}

.react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
}