.floating-button {
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    width: 7rem;
    height: 7rem;
    background: var(--color-active-brand);
    border-radius: 100%;
    cursor: pointer;
    user-select: none;
    z-index: 1005;
    
    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2rem;
        height: 3px;
        margin-top: -1px;
        margin-left: -1rem;
        background: var(--color-passive-light);
    }
    
    &:after {
        transform: rotate(90deg);
    }
}