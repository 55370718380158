@import './colors.scss';
@import './button.scss';
@import './checkbox.scss';
@import './forms.scss';
@import './input.scss';
@import './tables.scss';
@import './radio.scss';
@import './drawer.scss';
@import './modal.scss';
@import './skeleton.scss';
@import './dropdown.scss';
@import './vars.scss';

.no-wrap-line {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.blue {
        color: var(--color-link);
    }
}
.center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex: 1 1 100%;

    &.offset {
        padding: 3rem;
    }
}

.ant-empty-description {
    color: var(--color-active-dark);
}

.ant-skeleton {
    .ant-skeleton-content .ant-skeleton-title {
        width: 75% !important;
        margin-top: 0;
    }

    &.ant-skeleton-active .ant-skeleton-avatar {
        border-radius: 1rem;
    }
}

.ant-skeleton-paragraph:empty {
    display: none;
}

.ant-badge-dot {
    width: 1rem;
    height: 1rem;
    border-radius: .5rem;
    background: var(--color-active-accept);
    top: 1.125rem;
    right: .875rem;
    box-shadow: none !important;
}

.ant-popover-inner-content {
    max-width: 37.5rem;
}

span.link {
    text-decoration: underline;
    cursor: pointer;
}

body.blur .admin-dashboard-content {
    filter: blur(3px);
}

.ant-spin-spinning {
    line-height: 0;
}

hr {
    border: 0;
    height: 1px;
    background-color: var(--color-border);
    margin: 0;
}