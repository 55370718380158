.list-item {
    display: inline-flex;
    align-items: center;
    height: 4rem;
    margin: 0.5rem 0;
    color: var(--color-active-dark);
    cursor: pointer;
    font-weight: 600;

    &.red-theme {
        color: var(--color-active-alert);
    }
}