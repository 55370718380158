.wallets-columns-filters {
    width: 4rem;
    height: 4rem;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F3F4F5;
    border: 1px solid transparentize($color: #1A2433, $amount: .9);
    border-radius: .5rem;
    cursor: pointer;

    svg {
        transform: scale(.75);
    }
}

.wallets-columns-dropdown {
    .ant-dropdown-menu {
        .options-section {
            padding: 1rem 2rem;
            border-bottom: 1px solid var(--color-passive-medium);

            .ant-radio-wrapper {
                margin-right: 0;
                margin-left: 2rem;
            }

            .ant-checkbox-inner {
                border-color: #d9d9d9;
            }
        }
    }
}