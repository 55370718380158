.user-item {
    display: block;
    padding: 0 1rem 0 0;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;

    & > span {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.625rem;
        max-width: calc(100% - 3.75rem);

        strong {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: normal;
            line-height: 1.3;
        }

        span {
            display: block;
        }
    }

    & > .photo {
        display: inline-block;
        margin-right: 2rem;
        font-size: 0;
        vertical-align: middle;
    }
    
    & > .name {
        vertical-align: middle;
        display: inline-flex;
        flex-direction: column;
        line-height: 1.1;
    }

    .desc {
        opacity: .5;
        font-size: .9em;
        height: 1.5em;
    }
}

.tagged {
    text-transform: uppercase;
    font-size: 1.25rem !important;
    color: var(--color-passive-mark);
    border: 1px solid var(--color-passive-mark);
    padding: 0 .625rem;
    margin-left: 1.25rem;
    border-radius: .5rem;
}