.slider-content {
    position: relative;
    
    .control {
        position: relative;
        width: 4rem;
        height: 4rem;
        cursor: pointer;
    
        &:after {
            content: "";
            position: absolute;
            top: 0.5rem;
            right: 0.25rem;
            width: 1rem;
            height: 1rem;
            background: var(--color-active-accept);
            border-radius: 100%;
            transform: scale(0);
            transition: transform 0.5s;
        }

        &.has-checked-options:after {
            transform: scale(1);
        }
    }
    
    .control-inner {
        display: block;
        width: 4rem;
        height: 4rem;
        border-radius: .5rem;
        cursor: pointer;
        transition: background-color 0.25s;

        &.expanded {
            background-color: var(--color-passive-medium);
        }
    }
    
    .icon {
        display: block;
        width: inherit;
        height: inherit;
        background: center center no-repeat;
        background-size: cover;
    }
    
    .content {
        position: fixed;
        z-index: 100;
        right: 0;
        width: 0;
        height: 100%;
        overflow: hidden;
        transition: width 0.5s;
        pointer-events: none;

        &.expanded {
            width: 32rem;
            pointer-events: all;

            .content-inner {
                right: 0;
            }
        }
    }
    
    .content-inner {
        display: flex;
        position: absolute;
        top: 0;
        right: -32rem;
        width: 32rem;
        height: inherit;
        background: var(--color-passive-light);
        border-top: 1px solid var(--color-passive-medium);
        border-left: 1px solid var(--color-passive-medium);
        overflow: hidden;
        transition: right 0.5s;
    }
    
}