.site-navigation {
    display: flex;
    align-items: center;

    .items {
        display: flex;
      
        .item {
            &:not(:first-child) {
                margin-left: 1rem;
            }
            
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }
      
    .item-link {
        color: inherit;
        transition: color 0.25s; 

        &:hover {
            color: var(--color-passive-light);
        }
    }
      
    .legal {
        margin-left: 2rem;
    }
}