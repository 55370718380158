.ant-btn {
    display: inline-block;
    height: 4rem;
    padding: 0 1rem;
    border-radius: 2px !important;
    background: var(--color-passive-light);
    border: 1px solid var(--color-passive-medium);
    color: var(--color-base-dark);
    cursor: pointer;
    user-select: none;

    &:active,
    &:focus {
        color: var(--color-base-dark);
    }

    &:hover {
        color: var(--color-active-brand);
    }

    &.ant-btn-text,
    &.ant-btn-link {
        border: none;
    }

    &.ant-btn-text {
        color: var(--color-passive-mark);
    }
    
    &[disabled] {
        cursor: default;
    }
    
    & span {
        font-weight: 600;
    }

    &.centered {
        display: block;
        margin: 0 auto;
    }
    
    &.fit-container,
    &.tabee-login__submit {
        width: 100%;

        &.high {
            padding-top: 2rem;
            padding-bottom: 2rem;
            height: auto;
        }
    }
    
    &.ant-btn-primary {
        background-color: var(--color-active-brand);
        border-color: var(--color-active-brand);
        color: var(--color-passive-light);
        
        &[disabled] {
            background: var(--color-passive-light);
            border: 1px solid var(--color-passive-medium);
            border-radius: 1px;
            color: var(--color-passive-mark);
        }

        &.ant-btn-dangerous {
            background-color: var(--color-active-alert);
            border-color: var(--color-active-alert);
        }
    }

    &.ant-btn-icon-only {
        padding: 0;
        font-size: 0;

        &.no-border {
            border: none !important;
            background-color: transparent !important;
        }

        &.smaller > * {
            font-size: 1rem;

            svg {
                transform: scale(.85);
            }
        }
    }
}