* {
    box-sizing: border-box;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
input, button,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    background: transparent none;
    border: 0;
    font-size: 100%;
    outline: 0;
    vertical-align: baseline;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote::before, blockquote::after,
q::before, q::after {
    content: "";
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
body,
.root {
    height: 100%;
    background-color: #fff;
}

html {
    font-size: var(--module-size);
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

.app-page {
    display: flex;
    height: 100%;
    font: 1.65rem "Open Sans", sans-serif;
    color: var(--color-active-dark);
}

.display-on-hover-content {
    opacity: 0;
    transition: opacity 0.125s;
}
.display-on-hover-container:hover .display-on-hover-content {
    opacity: 1;
}

.title-fix {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 4rem;
}

.admin-dashboard svg path,
.public-loader svg path {
    fill: currentColor;
    -webkit-transition: fill 0.25s;
    -o-transition: fill 0.25s;
    transition: fill 0.25s;
}
