.navigation-item {
    display: flex;
    align-items: center;
    height: 8rem;
    color: var(--color-passive-light);
    cursor: pointer;
    transition: color 0.25s;
    overflow: hidden;
    position: relative;

    &:after {
        content: '';
        width: 4rem;
        height: 12.5rem;
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(90deg, rgba(26,36,51,0) 0%, var(--color-active-dark) 100%);
        opacity: 0;
        transition: opacity .1s ease;
        pointer-events: none;
    }

    &.active {
        color: var(--color-active-brand);
        cursor: default;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6rem;
        height: 4rem;
        flex: 0 0 6rem;

        svg {
            width: auto;
            height: 100%;
        }
        
        path {
            fill: currentColor;
            transition: fill 0.25s;
        }
    }

    .name {
        flex-grow: 1;
        padding-left: 1rem;
        font-weight: 600;
        line-height: 1rem;
        white-space: nowrap;
    }
    
    .parent-name {
        position: relative;
    
        &:after {
            content: "";
            position: absolute;
            width: 2rem;
            height: 100%;
            top: 0;
            right: 1rem;
            background: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+DQogIDxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjkiIGQ9Ik0xMyA2bC01IDUtNS01Ii8+DQo8L3N2Zz4=) center center no-repeat;
            transform: rotate(-90deg);
        }
    }
}