.passcode-popover {
    .passcode-popover-mask {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.5);
        z-index: 9000;
    }

    .passcode-popover-content {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: var(--color-active-dark);
        color: var(--color-passive-light);
        z-index: 9001;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        transition: transform .5s ease;
        transform: translateY(100%);
        padding: 5rem 0;

        h1 {
            font-size: 2.5rem;
            color: #fff;
            margin-bottom: 2rem;
        }

        p {
            font-size: 1.625rem;
            margin-bottom: 2rem;
        }

        .passcode-popover-form {
            padding: 2rem 0 4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .passcode-popover-form-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: stretch;

                & > * {
                    margin-right: 2rem;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .passcode-error {        
        transform: translateY(-12px);
    }

    &.visible {
        .passcode-popover-content {
            transform: none;
        }
    }
}