.admin-dashboard.team-dashboard {
    .ant-table-tbody > tr > td,
    .ant-table-tbody > tr > td:first-child {
        padding: 1rem 2rem;

        &:last-child {
            padding-right: 3rem;
        }
    }

    td > .image-container {
        display: inline-block;
        width: 6rem;
        height: 8rem;
    }

    td > .action-btn {
        opacity: 0;
    }

    tr:hover,
    tr.hover-lock {
        & > td > .action-btn {
            opacity: 1;
        }
    }
}