.ant-dropdown-menu {
    padding: 0;

    &.dark-theme {
        background-color: var(--color-passive-dark);

        .ant-dropdown-menu-item, 
        .ant-dropdown-menu-submenu-title {
            color: var(--color-passive-mark);

            &:hover {
                color: var(--color-passive-medium-mark);
                background-color: transparent;
            }
        }

        .ant-dropdown-menu-item-selected, 
        .ant-dropdown-menu-submenu-title-selected, 
        .ant-dropdown-menu-item-selected > a, 
        .ant-dropdown-menu-submenu-title-selected > a {
            color: var(--color-passive-stripped-light);
            background-color: transparent;
        }
    }
}