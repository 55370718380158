

.ant-drawer {
    pointer-events: none;
    overflow: hidden;

    .ant-drawer-content {
        overflow: hidden;
    }

    &.ant-drawer-right .ant-drawer-content-wrapper {
        box-shadow: none;
        border-left: 1px solid var(--color-border);
    }

    .ant-drawer-mask {
        left: 8rem;
        height: var(--admin-header-height);
        background-color: transparent;
        width: 80vw;
    }

    &.ant-drawer-open {
        .ant-drawer-content-wrapper {
            pointer-events: all;
        }

        .ant-drawer-mask {
            left: 8rem;
            height: var(--admin-header-height);
        }
    }
}

.ant-drawer-body {
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #fff;
    flex: 1 1 100%;
}