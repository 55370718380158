.form-section {
    .form-section-title {
        width: 100%;
        padding: 0 4rem;
        height: 4rem;
        line-height: 4rem;
        font-size: 1.625rem;
        color: var(--color-passive-mark);
        background-color: var(--color-passive-medium);
    }
    
    .form-section-content {
        padding: 3rem 4rem;

        .ant-row {
            margin-bottom: 1rem;
        }

        .ant-form-item-label {
            text-align: left;

            label {
                color: var(--color-passive-mark);
            }
        }
    }

    .inline-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        & > * {
            margin-right: 2rem;
        }
    }

    .flexrow {
        .ant-form-item-control-input-content {
            display: flex;
            flex-direction: row;

            & > * {
                flex: 0 0 50%;
            }
        }
    }
}