.todo-companion {
    padding: 2rem;
    min-width: 36rem;

    .todo-filters {
        border-bottom: 1px solid var(--color-passive-medium);
        padding-bottom: 3rem;

        .todo-filter {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: .5rem 0;
            user-select: none;

            .todo-filter-title {
                flex: 1 1 100%;
                padding: 0 1rem;
                cursor: pointer;
            }

            .todo-filter-action {
                white-space: nowrap;
                opacity: 0;
                transition: opacity .1s ease;
                color: var(--color-active-brand);
                cursor: pointer;
            }

            &:hover {
                .todo-filter-action {
                    opacity: 1;
                }
            }

            &:first-child {
                .todo-filter-title {
                    font-weight: bold;

                    .todo-filter-count {
                        font-weight: normal;
                    }
                }
            }
        }
    }

    .todo-categories {
        padding-top: 1rem;

        .todo-tag {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: .5rem 0;

            &.tag-selected {
                .todo-tag-title {
                    font-weight: bold;
                }
            }

            .todo-tag-wrapper {
                flex: 0 0 auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
            }

            .todo-tag-title {
                flex: 1 1 100%;
                padding: 0 1.25rem;
                text-align: left;
                white-space: nowrap;
            }
            
            .todo-tag-icon {
                flex: 0 0 1.5rem;
                height: 1.5rem;
                display: inline-block;
                vertical-align: middle;
                border-radius: 1.5rem;
                background-color: #ccc;
                margin-left: .25rem;

                &.todo-tag-id-untagged {
                    background-color: transparent;
                    border: 1px solid #666;
                }
            }
            
            .todo-tag-action {
                white-space: nowrap;
                opacity: 0;
                transition: opacity .1s ease;
                color: var(--color-active-brand);
                cursor: pointer;
            }

            &:hover {
                .todo-tag-action {
                    opacity: 1;
                }
            }
        }

        .todo-add-tag {
            margin-top: 1rem;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: .5rem 0;
            cursor: pointer;
            transition: color .1s ease;

            &:before {
                content: '+';
                font-size: 2rem;
                width: 3rem;
                text-align: center;
            }

            &:hover {
                color: var(--color-active-brand);
            }
        }
    }
}

@media (prefers-color-scheme: dark) {
    .todo-companion {
        color: var(--color-passive-light);
    }
}