.admin-content {
    flex: 1 1 100%;
    overflow: hidden;
    /*position: relative;*/
    display: flex;
    flex-direction: column;

    & > .ant-table-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        flex: 1 1 100%;

        .ant-spin-nested-loading {
            height: 100%;

            .ant-spin-container {
                height: 100%;

                .ant-table {
                    height: 100%;

                    .ant-table-container {          
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        overflow-y: auto;

                        th,td {
                            &.space-cell {
                                & + td,
                                & + th {
                                    border-left: none;
                                }
                            }
                        }

                        .ant-table-header {
                            flex: 0 0 auto;
                        }

                        .ant-table-body {
                            flex: 1 0 auto;
                        }

                        .ant-table-sticky-scroll {
                            opacity: 1;
                            position: fixed;
                            left: 8rem;
                            bottom: 0;

                            .ant-table-sticky-scroll-bar {
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
